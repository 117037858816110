{
    "default": {
        "okta": {
            "cid": "0oarkdjdkyIqi3oOW1t7",
            "iss": "https://ring.okta.com/oauth2/ausrhv2wyg1f2pWnl1t7"
        },
        "api_prefix": "https://dcms.cs-tools.dev.ring.com",
        "nd_prefix": "https://dashboard.ring.com/customer/",
        "kustomer_prefix": "https://zzz-ring-sandbox.kustomerapp.com/app/customers/"
    },
    "dcms.cs-tools.dev.ring.com": {
        "okta": {
            "cid": "0oarkdjdkyIqi3oOW1t7",
            "iss": "https://ring.okta.com/oauth2/ausrhv2wyg1f2pWnl1t7"
        },
        "api_prefix": "https://dcms.cs-tools.dev.ring.com",
        "nd_prefix": "https://dashboard.ring.com/customer/",
        "kustomer_prefix": "https://zzz-ring-sandbox.kustomerapp.com/app/customers/"
    },
    "dcms.cs-tools.qa.ring.com": {
        "okta": {
            "cid": "0oarkdjdkyIqi3oOW1t7",
            "iss": "https://ring.okta.com/oauth2/ausrhv2wyg1f2pWnl1t7"
        },
        "api_prefix": "https://dcms.cs-tools.qa.ring.com",
        "nd_prefix": "https://dashboard.ring.com/customer/",
        "kustomer_prefix": "https://zzz-ring-sandbox.kustomerapp.com/app/customers/"
    },
    "dcms.cs-tools.ring.com": {
        "okta": {
            "cid": "0oarkdkzau9rA9dr41t7",
            "iss": "https://ring.okta.com/oauth2/ausrhv2wyg1f2pWnl1t7"
        },
        "api_prefix": "https://dcms.cs-tools.ring.com",
        "nd_prefix": "https://dashboard.ring.com/customer/",
        "kustomer_prefix": "https://ring.kustomerapp.com/app/customers/"
    }
}
