//Page
import React from 'react';
import PageContent from '../components/layouts/PageContent';
//CSS
import './css/dashboard.css';
//Components
import DcmsDashboard from '../components/dcms-dashboard/DcmsDashboard';

function DcmsDashboardPage() {
    return (
        <PageContent
            headerColor='#10628a'
            header='CS Tools'
            subheader='DCMS Dashboard'
            footer={true}
            authenticated={true}
        >
            <DcmsDashboard />
        </PageContent>
    );
}

export default DcmsDashboardPage;
