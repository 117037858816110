import React, { useEffect, useState } from 'react';
import axios from 'axios';

// Elements
import { Button, Card, Table, message } from 'antd';
import { ReloadOutlined, LikeOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import Swal from 'sweetalert2';

// CSS
import './dcms-dashboard.css';

// Variables
import settings from '../../settings.json';
import { useContinuousAuth } from '../../pages/App';

//URL Prefix Defined
const {
    api_prefix: apiPrefix,
    nd_prefix: nd_prefix,
    kustomer_prefix: kustomer_prefix
} = settings[window.location.host] || settings.default;

function DcmsDashboard() {

    const [exportJobs, setexportJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [expandedRowIndicator, setExpandedRowIndicator] = useState([])

    const { getHeaders } = useContinuousAuth();

    useEffect(() => {
        listExports();
    }, []);

    const listExports = async () => {
        setLoading(true);
        try {
            const headers = await getHeaders();
            const { data } = await axios.post(apiPrefix + '/api/dcms-jobs', {
                operation: "LIST",
                verified: false
            }, { headers });
            setexportJobs(data.results);
            setLoading(false);
        } catch (e) {
            message.error('There was a problem with your Request');
            console.error("Error getting exports", e.message);
        }
    }

    const reprocessExport = async (requestId) => {
        setLoading(true);
        try {
            const headers = await getHeaders();
            const { data } = await axios.post(apiPrefix + '/api/dcms-jobs', {
                operation: "REPROCESS",
                jobRequestId: requestId,
            }, { headers });
            setExpandedRowIndicator([]);
            if (data.statusCode === 200) {
                message.success(`Successful call: ${requestId}`);
            } else {
                message.error(`Error: ${data.message}`);
            }
            await listExports();
            setLoading(false);
        } catch (e) {
            message.error('There was a problem with your Request');
            console.error("Error getting exports", e.message);
        }
    }

    const confirmExport = async (requestId) => {
        setLoading(true);
        try {
            const headers = await getHeaders();
            const { data } = await axios.post(apiPrefix + '/api/dcms-jobs', {
                operation: "CONFIRM",
                jobRequestId: requestId,
            }, { headers });
            setExpandedRowIndicator([]);
            if (data.statusCode === 200) {
                message.success(`Successful call: ${requestId}`);
            } else {
                message.error(`Error: ${data.message}`);
            }
            await listExports();
            setexportJobs(exportJobs.filter(item => item.requestId !== requestId));
            setLoading(false);
        } catch (e) {
            message.error('There was a problem with your Request');
            console.error("Error getting exports", e.message);
        }
    }

    // Expanded Row Render
    const expandedRowRender = (event) => {
        // Reprocess Fetch
        const reprocessFetch = () => {
            Swal.fire({
                title: "Are you sure?",
                text: "Selecting confirm below will Reprocess your request",
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
            }).then((result) => {
                if (result.isConfirmed) {
                    reprocessExport(event.request_id);
                }
            })
        }

        // Approve Fetch
        const approveFetch = () => {
            Swal.fire({
                title: "Are you sure?",
                text: "Selecting confirm below will Approve your request",
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Confirm',
            }).then((result) => {
                if (result.isConfirmed) {
                    confirmExport(event.request_id);
                }
            })
        }

        return (
            <Card
                title="Request Details"
                headStyle={{ 
                    color: 'rgba(0, 0, 0, 0.45)', 
                    fontFamily: 'equipextralight' 
                }}
            >
                <p>
                    <span className="request-label">
                        Request Id:
                    </span><br />
                    <span className="request-value">
                        {event.request_id || "N/A"}
                    </span>
                </p>
                <p>
                    <span className="request-label">
                        User Id:
                    </span><br />
                    <span className="request-value">
                        {event.user_id || "N/A"}
                    </span>
                </p>
                <p>
                    <span className="request-label">
                        Export Summary:
                    </span><br />
                    <span className="request-value">
                        {event.exportSummary || "N/A"}
                    </span>
                </p>
                <p>
                    <span className="request-label">
                        Export Date:
                    </span><br />
                    <span className="request-value">
                        {event.lastExportDate || "N/A"}
                    </span>
                </p>
                <p>
                    <span className="request-label">
                        Export Path:
                    </span><br />
                    <span className="request-value">
                        {event.lastExportPath || "N/A"}
                    </span>
                </p>
                <p>
                    <span className="request-label">
                        Kustomer URL:
                    </span><br />
                    <span className="request-value">
                        <a target="_blank" href={kustomer_prefix + event.kustomerId}>
                            Kustomer Link
                        </a>
                    </span>
                </p>
                <p>
                    <span className="request-label">
                        ND URL:
                    </span><br />
                    <span className="request-value">
                        <a target="_blank" href={nd_prefix + event.user_id + '/dashboard'}>
                            Neighbor Dashboard Link
                        </a>
                    </span>
                </p>
                <p>
                    <span className="request-label">
                        Last Error Message:
                    </span><br />
                    <span className="request-value">
                        {event.lastErrorMessage || "N/A"}
                    </span>
                </p>
                <Button
                    type="primary"
                    shape="round"
                    icon={<ReloadOutlined />}
                    onClick={reprocessFetch}
                    className="reprocess-button"
                >
                    Reprocess
                </Button>
                {event.requestZipStatus === "success" ?
                    <Button
                        type="primary"
                        shape="round"
                        icon={<LikeOutlined />}
                        onClick={approveFetch}
                        className="approve-button"
                    >
                        Approve
                    </Button> :
                    <div></div>
                }
            </Card>
        );
    }

    const columns = [
        {
            title: 'Request Id',
            dataIndex: 'request_id'
        },
        {
            title: 'User Id',
            dataIndex: 'user_id'
        },
        {
            title: 'Age',
            dataIndex: 'createdAt',
            render(date, row) {
                date = date.charAt(date.length - 1) === "Z" ? date : date + "Z";
                return Math.round((new Date().getTime() - new Date(date).getTime()) / (1000 * 3600 * 24));
            }
        },
        {
            title: 'Created Date',
            dataIndex: 'createdAt',
            sorter: {
                compare: (a, b) => new Date(a.createdAt.substring(0, 10)).getTime() - new Date(b.createdAt.substring(0, 10)).getTime(),
                multiple: 1,
            },
            render(date, row) {
                date = date.charAt(date.length - 1) === "Z" ? date : date + "Z";
                return new Date(date).toString()
            }
        },
        {
            title: 'Export Status',
            dataIndex: 'request_status',
        },
        {
            title: 'Zip Status',
            dataIndex: 'requestZipStatus',
        },
        {
            title: 'Owner',
            dataIndex: 'requestOwner'
        },
        // Column - Status Indicator
        {
            title: 'Ready for Review',
            filters: [
                { text: 'Yes', value: true },
                { text: 'No', value: false },
            ],
            onFilter: (value, record) => value === !!Object.keys(record).find(key => key.includes('requestZipStatus') && record.requestZipStatus === "success"),
            render(record, row) {
                if (record.request_status === "exported" && record.requestZipStatus === "success") {
                    return <CheckCircleTwoTone twoToneColor="#52c41a" />;
                }

                return null;
            }
        },
        {
            title: 'Error',
            render(record, row) {
                if (record.emailMismatch) {
                    return <span>Ring and Kustomer Emails Mismatch.<br /></span>
                } else if (record.request_status === "failed") {
                    return record.lastErrorMessage;
                }

                return null;
            }
        },

    ];

    return (
        <div className="dcms-dashboard-table" style={{ marginTop: '20px' }}>
            <Table
                columns={columns}
                rowKey={record => record.request_id}
                loading={loading}
                expandable={{
                    expandedRowRender: expandedRowRender,
                    rowExpandable: record => record.request_id !== 'Not Expandable',
                    onExpand: record => setExpandedRowIndicator(record.key),
                    expandedRowKeys: expandedRowIndicator

                }}
                dataSource={exportJobs}
            />
        </div>
    );
}

export default DcmsDashboard;
